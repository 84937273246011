<!--
  - Copyright (C) 2019. Archimedes Exhibitions GmbH,
  - Saarbrücker Str. 24, Berlin, Germany
  -
  - This file contains proprietary source code and confidential
  - information. Its contents may not be disclosed or distributed to
  - third parties unless prior specific permission by Archimedes
  - Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
  - to copies made in any form and using any medium. It applies to
  - partial as well as complete copies.
  -->

<template>
  <div>
    <b-container class="py-4" fluid>
      <!-- Errors -->
      <b-alert variant="danger" show v-if="driverError">
        <b>Driver Error:</b> {{ driverError }}
      </b-alert>

      <div class="search-wrapper mb-5 mt-4">
        <input type="text" ref="search" v-model="searchQuery"
               :placeholder="$t('ems.common.filterNameType')"/>
      </div>
      <!--     Table content-->
      <b-table hover head-variant="light" :items="resultQuery"
               id="data-table" :per-page="perPage" :fields="tableFields"
               :current-page="currentPage">
        <template v-slot:cell(status)="status">
          <b v-bind:class="{'text-success' : status.value === 'ACTIVE',
           'text-warning' : status.value === 'INACTIVE' }">
            {{ ($t('ems.common.' + status.value.toLowerCase())).toUpperCase() }}</b>
        </template>
      </b-table>

      <b-alert class="text-center" show v-if="!resultQuery.length && !loading">
        <b-icon-exclamation-diamond></b-icon-exclamation-diamond> {{ $t('ems.common.nothingFound') }}
      </b-alert>

      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                    aria-controls="data-table" align="center" class="mt-5">
      </b-pagination>
    </b-container>
    <b-alert
      v-model="loading"
      class="position-fixed fixed-bottom m-0 rounded-0 text-center"
      style="z-index: 2000;"
      variant="primary">
      <b-icon-alarm></b-icon-alarm> {{ $t('ems.common.loadingWait') }}
    </b-alert>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        loading: false,
        driverError: null,
        driverData: [],
        visibleDriverSettings: null,
        searchQuery: null,
        perPage: 20,
        currentPage: 1,
        tableFields: [
          {
            key: 'name',
            label: this.$t('ems.common.name')
          },
          {
            key: 'info',
            label: this.$t('ems.common.info'),
            thClass: 'd-none d-xl-table-cell',
            tdClass: 'd-none d-xl-table-cell'
          },
          {
            key: 'status',
            label: this.$t('ems.common.status'),
            sortable: true,
            thClass: 'text-center',
            tdClass: 'text-center'
          }
        ]
      }
    },
    watch: {
      driverData () {
        if (this.driverData.length) {
          this.$refs.search.focus()
        }
      }
    },
    computed: {
      tableData () {
        let tableData = []
        this.driverData.forEach(d => {
          tableData.push({
            name: d.alias,
            info: d.info,
            status: d.active ? 'ACTIVE' : 'INACTIVE',
            settings: d
          })
        })
        return tableData
      },
      resultQuery () {
        if (this.searchQuery) {
          return this.tableData.filter((item) => {
            return this.searchQuery
              .toLowerCase().split(' ')
              .every(v => item.name.toLowerCase().includes(v))
          })
        } else {
          return this.tableData
        }
      },
      rows () {
        return this.resultQuery.length
      }
    },
    methods: {
      updateDrivers () {
        this.loading = true
        this.driverData = []
        this.$driverManager.updateDrivers()
          .then(() => {
            for (let driver of this.$driverManager.drivers) {
              driver.active = true
              this.driverData.push(driver)
            }
            for (let driver of this.$driverManager.unimplementedDrivers) {
              driver.alias = driver.name
              driver.active = false
              this.driverData.push(driver)
            }
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
            console.error(err)
            this.driverError = err
          })
      },

      openSideBar (driver) {
        this.visibleDriverSettings = driver
        this.$refs.settings.open()
      }
    },

    created () {
      this.updateDrivers()
    }
  }
</script>
